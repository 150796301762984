import Vue from 'vue'
import Router from 'vue-router'

// const login = resolve => require(['../components/helloWorld'], resolve)
// 错误页
const errorPage = () => import('../components/error_page')
const login = () => import('../components/login')
const main = () => import('../components/outer-frame')
const index = () => import('../components/index')
// const mine = () => import('../components/mine')
const rechargeProlong = () => import('../components/contentPage/recharge-prolong')
const rechargeRecord = () => import('../components/contentPage/recharge-record')
const addVh = () => import('../components/contentPage/add-vh')
const remoteOpenGate = () => import('../components/contentPage/remote-open-gate')
const inoutRecord = () => import('../components/contentPage/inout-record')
const inoutDetail = () => import('../components/contentPage/inout-detail')
const accountDetail = () => import('../components/contentPage/account-detail')
const reg = () => import('../components/contentPage/reg')
const addBlack = () => import('../components/contentPage/add_black')
const guestAppoint = () => import('../components/contentPage/guest-appoint')

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/error_page',
      name: 'error_page',
      component: errorPage
    },
    {
      path: '/reg',
      name: '账号注册',
      component: reg
    },
    {
      path: '/main',
      name: 'main',
      component: main,
      children: [
        {
          path: '/index',
          name: 'index',
          component: index
        },
        // {
        //   path: '/mine',
        //   name: 'mine',
        //   component: mine
        // },
        {
          path: '/recharge_prolong',
          name: '充值延期',
          component: rechargeProlong
        },
        {
          path: '/recharge_record',
          name: '充值记录',
          component: rechargeRecord
        },
        {
          path: '/add_vh',
          name: '添加固定车',
          component: addVh
        },
        {
          path: '/remote_open_gate',
          name: '远程开闸',
          component: remoteOpenGate
        },
        {
          path: '/inout_record',
          name: '出入查询',
          component: inoutRecord
        },
        {
          path: '/inout_detail',
          name: '出入详情',
          component: inoutDetail
        },
        {
          path: '/account_detail',
          name: '账号详情',
          component: accountDetail
        },
        {
          path: '/add_black',
          name: '新增黑名单',
          component: addBlack
        },
        {
          path: '/guest_appoint',
          name: '访客预约',
          component: guestAppoint
        }
      ]
    },
    {
      path: '*',
      redirect: { path: '/login' }
    }
  ]
})
