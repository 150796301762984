// import router from '../router'
// import * as common from '../api/common'
export const mutations = {
  commitUserInfo (state, info) {
    if (!info && window.localStorage.getItem('userInfo')) {
      state.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    } else if (info) {
      state.userInfo = info
      window.localStorage.setItem('userInfo', JSON.stringify(info))
    } else {
      window.localStorage.removeItem('userInfo')
      state.userInfo = null
    }
  },
  commitAccessToken (state, accessToken) {
    console.log('9999999999', state, accessToken)
    if (!accessToken && window.localStorage.getItem('accessToken')) {
      state.accessToken = window.localStorage.getItem('accessToken')
    } else if (accessToken) {
      state.accessToken = accessToken
      window.localStorage.setItem('accessToken', accessToken)
    } else {
      window.localStorage.removeItem('accessToken')
      state.accessToken = ''
    }
  },
  commitOnline (state, online) {
    console.log('online', online)
      state.online = online
  }
}
