// import * as api from '../api/api'

export const activeUserInfo = ({commit}) => {
  let info
  if (localStorage.getItem('userInfo')) {
    info = JSON.parse(localStorage.getItem('userInfo'))
    commit('commitUserInfo', info)
  }
}


