<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style lang="scss">
@import "./theme/index";
html, body, #app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 10px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $global-color;
  box-sizing: border-box;
  font-size: $global-font-size-normal;
}
.to-upper{
  /*Capitalize 英文拼音的首字母大写*/
  /*Uppercase 英文拼音字母全大写*/
  /*Lowercase 英文拼音字母全小写*/
  text-transform:uppercase
}
</style>
